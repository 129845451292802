import './App.css';
import './App_mobile.css';
import './buttons.css'
import React, {useState, useEffect} from 'react'
import Image1 from './components/images/nursing-home-3.png'
import Image2 from './components/images/nursing-home-2.png'
import Image3 from './components/images/nursing-home-4.png'
import Image4 from './components/images/nursing-home.png'
import Image5 from './components/images/image0.jpeg'
import axios from'axios'

function App() {

  const [choice, setChoice] = useState("5")
  const [caregiver, setCaregiver] = useState([])
  const [reviews, setReviews] = useState([])
  const [reviewsCompany, setReviewsCompany] = useState([])
  const [service, setService] = useState(0)
  const [error, setError] = useState("")

  const [aideReview, setAideReview] = useState([])
  const [aideImage, setAideImage] = useState("")

  const [data1, setData1] = useState("")
  const [data2, setData2] = useState("")
  const [data3, setData3] = useState("")
  const [data33, setData33] = useState("")
  const [data4, setData4] = useState("")

  const [data5, setData5] = useState("")
  const [data6, setData6] = useState("")
  const [data7, setData7] = useState("")
  const [data8, setData8] = useState("")
  const [data9, setData9] = useState("")
  const [data0, setData0] = useState("")
  const [data11, setData11] = useState("")

  const [data12, setData12] = useState("")
  const [data13, setData13] = useState("")
  const [data14, setData14] = useState("")
  const [data15, setData15] = useState("")
  const [data16, setData16] = useState("")
  const [data17, setData17] = useState("")
  
  const jnfrei4 = process.env.REACT_APP_1
  const v09ns9 = process.env.REACT_APP_2
  const hnfw4vs = process.env.REACT_APP_3
  const vdsr4 = process.env.REACT_APP_4
  const d093 = process.env.REACT_APP_5

  const cn90cs = process.env.REACT_APP_6
  const svw439 = process.env.REACT_APP_7
  const csdw09 = process.env.REACT_APP_8
  const vsds00 = process.env.REACT_APP_9

  const f083 = process.env.REACT_APP_4444

  const vs4ewcs = process.env.REACT_APP_0

  const csniw0 = process.env.REACT_APP_11
  const csd4w40 = process.env.REACT_APP_22
  const scvsdon0 = process.env.REACT_APP_33
  
  const csdfw94w = process.env.REACT_APP_44
  const scdfgtge90w = process.env.REACT_APP_55
  const svdsr = process.env.REACT_APP_66
  const csrfsr09r3 = process.env.REACT_APP_77
  const fsonofw0 = process.env.REACT_APP_88
  const sdcvsktn9 = process.env.REACT_APP_99

  const fsgsojrng30 = process.env.REACT_APP_222
  const sdgfosrn39 = process.env.REACT_APP_333
  const sdjsfg0 = process.env.REACT_APP_444

  const dsnwn430 = process.env.REACT_APP_555
  const gsfnoifn34 = process.env.REACT_APP_667
  const fsnifs = process.env.REACT_APP_777
  
  const DataMarker = async(request) =>{
      switch(request){
        case 5: 
          try{
              const Request_6 = await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          [hnfw4vs] : "",
                                          [vdsr4] : {
                                              [d093] : csd4w40
                                          }
                                      }).then(Item =>{
                                          if(Item.data.request){
                                            setReviewsCompany(Item.data.response)
                                            return null
                                          }else{
                                            setError(Item.data.response)
                                            return null
                                          }
                                      }).catch(Error =>{
                                        setError("Company Review Error - 1")
                                        return false 

                                      })
                                    }catch(Error){
                                      setError("Company Review Error - 2")
                                      return false
                                    }
              return null
        case 6: 
          try{
              const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                      [jnfrei4] : dsnwn430,
                                      [v09ns9] : gsfnoifn34,
                                      [vdsr4] : {
                                          [d093] : scvsdon0
                                      }

                                  }).then(Item =>{
                                      //console.log(Item.data)
                                      if(Item.data.request){
                                        try{
                                          //console.log(Item.data.response)
                                          setCaregiver(Item.data.response)
                                          setData4(Item.data.response[0]._id)
                                          return null
                                        }catch(error){

                                        }

                                      }else{
                                        //console.log(Item.data.response)
                                        setError(Item.data.response)
                                        return null
                                      }
                                  }).catch(Error =>{
                                    //console.log(Error)
                                    setError("Caregiver Error - 1")
                                    return false 

                                  })
                                }catch(Error){
                                  setError("Caregiver Error - 2")
                                  return false
                                }
              return null

        default:
          return false
      }
    }
    const DataMark = async(e, request, data) =>{
      e.preventDefault()
      switch(request){   
            case 0: 
              try{
                  //console.log("Create Aide Review")
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          [vdsr4] : {
                                              [d093] : cn90cs,
                                              [fsnifs] : {
                                                  [csdfw94w] : data1,
                                                  [scdfgtge90w] : data2,
                                                  [svdsr] : data4,
                                                  [csrfsr09r3] : data3,
                                                  E_mail : data33
                                              }
                                          }
                                      }).then(Item =>{
                                        //console.log(Item)
                                        if(Item.data.request){
                                          setData1("")
                                          setData2("")
                                          setData3("")
                                          setData33("")
                                          setData4("")
                                          setError("")
                                          return null
                                        }else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        setError("Aide Review Error - 1")
                                        return false 

                                      })
                                    }catch(Error){
                                      setError("Aide Review Error - 2")
                                      return false
                                    }
                  return null

            case 1: 
              try{
                  //console.log("Create Company Review")
                  const Request_2 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9]: gsfnoifn34,
                                          [hnfw4vs] : "",
                                          [vdsr4] : {
                                              [d093] : svw439,
                                              [fsnifs] : {
                                                  [csdfw94w] : data1,
                                                  [scdfgtge90w] : data2,
                                                  [svdsr] : data4,
                                                  [csrfsr09r3] : data3,
                                                  E_mail : data33
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          setData1("")
                                          setData2("")
                                          setData3("")
                                          setData33("")
                                          setData4("")
                                          setError("")
                                          return null
                                        }else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        setError("Review Error - 1")
                                        return false 

                                      })
                                    }catch(Error){
                                      setError("Review Error - 2")
                                      return false
                                    }
                  return null

            case 2: 
              try{
                  //console.log("Create Career")
                  const Request_3 =   await axios.post(`${vs4ewcs}`,{     
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          [hnfw4vs] : "",
                                          [vdsr4] : {
                                              [d093] : csdw09,
                                              [fsnifs] : {
                                                  [csdfw94w] : data5,
                                                  [scdfgtge90w] : data6,
                                                  [fsonofw0] : data7,
                                                  [sdcvsktn9] : data8,
                                                  [fsgsojrng30] : data55,
                                                  "Image" : data65,
                                                  "Position" : data11
                                              }
                                          }
                                      }).then(Item =>{
                                          if(Item.data.request){
                                            setData5("")
                                            setData6("")
                                            setData7("")
                                            setData8("")
                                            setData55("")
                                            setData65("")
                                            setData11("")
                                            setError("")
                                            return null
                                          }else{
                                            setError(Item.data.response)
                                            return null
                                          }

                                      }).catch(Error =>{
                                        //console.log(Error.response)
                                        setError("Career Error - 1")
                                        return false 

                                      })
                                    }catch(Error){
                                      setError("Career Error - 2")
                                      return false
                                    }
                  return null

            case 3: 
              try{
                  //console.log("Create Contact")
                  const Request_4 = await axios.post(`${vs4ewcs}`,{
                                              [jnfrei4] : dsnwn430,
                                              [v09ns9] : gsfnoifn34,
                                              [hnfw4vs] : "",
                                              [vdsr4] : {
                                                  [d093] : vsds00,
                                                  [fsnifs] : {
                                                      [csdfw94w] : data12,
                                                      [scdfgtge90w] : data13,
                                                      [fsonofw0]: data14,
                                                      [sdcvsktn9] : data15,
                                                      [sdgfosrn39] : data16,
                                                      [sdjsfg0] : data17
                                                  }
                                              }
                                          }).then(Item =>{
                                            if(Item.data.request){
                                              setData12("")
                                              setData13("")
                                              setData14("")
                                              setData15("")
                                              setData16("")
                                              setData17("")
                                              setError("")
                                              return null
                                            }else{
                                              setError(Item.data.response)
                                              return null
                                            }

                                          }).catch(Error =>{
                                            setError("Contact Error - 1")
                                            return false 

                                          })
                                        }catch(Error){
                                          setError("Contact Error - 2")
                                          return false
                                        }
                  return null

            case 4: 
              try{
                  //console.log("Retrieve Review Aide")
                  const Request_5 = await axios.post(`${vs4ewcs}`,{
                                              [jnfrei4] : dsnwn430,
                                              [v09ns9] : gsfnoifn34,
                                              [hnfw4vs] : "",
                                              [vdsr4] : {
                                                  [d093] : csniw0,
                                                  Nurse_ID : data
                                              }
                                          }).then(Item =>{
                                            if(Item.data.request){
                                              setAideReview(Item.data.response)
                                              setError("")
                                              return null
                                            }else{
                                              setError(Item.data.response)
                                              return null
                                            }

                                          }).catch(Error =>{
                                            setError("Aide Review Error - 1")
                                            return false 

                                          })
                                        }catch(Error){
                                          setError("Aide Review Error - 2")
                                          return false
                                        }
                  return null

            case 5: 
              try{
                  //console.log("Retrieve Image")
                  const Request_1 =   await axios.post(`${vs4ewcs}`,{
                                          [jnfrei4] : dsnwn430,
                                          [v09ns9] : gsfnoifn34,
                                          [vdsr4] : {
                                              [d093] : f083,
                                              [fsnifs] : {
                                                  Image_ID : data
                                              }
                                          }
                                      }).then(Item =>{
                                        if(Item.data.request){
                                          //console.log(Item.data)
                                          setAideImage(Item.data.response.Item.Image)
                                          setError("")
                                          return null
                                        }else{
                                          setError(Item.data.response)
                                          return null
                                        }

                                      }).catch(Error =>{
                                        //console.log(Error)
                                        setError("Aide Review Error - 1")
                                        return false 

                                      })
                                    }catch(Error){
                                      //console.log(Error)
                                      setError("Aide Review Error - 2")
                                      return false
                                    }
                  return null

            default : 
                return false
          }
    }
  
    useEffect (()=> {
      /*
        Retrieve Company 4, Aide 5, Caregivers 6
      */
    DataMarker(5)
    DataMarker(6)

    }, [])

    const [data55, setData55] = useState("")
    const [data65, setData65] = useState("")

    function INeedThisToDoSometing(inmyelement) {

      let bobbyd = inmyelement.files[0];
      let yomama = new FileReader();
      yomama.onloadend = function() {
          setData55(yomama.result)
      }
      yomama.readAsDataURL(bobbyd);
      
    }
    function INeedThisToDoSometing2(inmyelement) {

      let bobbyd = inmyelement.files[0];
      let yomama = new FileReader();
      yomama.onloadend = function() {
          setData65(yomama.result)
      }
      yomama.readAsDataURL(bobbyd);
      
    }

    const start_now = <button className="button-2" onClick={(e)=>{setChoice(4)}}>Start Now!</button>

    const serviceChoice = [
                            <div className="service_description_container">
                              <div className="service_title">
                                What is Companionship?
                              </div>
                              <div className="service_description"> 
                                Companionship is provided during all forms of care or by itself. Companionship involves providing emotional support, socialization, and assistance with various non-medical tasks to older adults or individuals with disabilities. These services aim to enhance the quality of life for clients by offering companionship and engaging in activities that promote well-being and independence.

                                <h3>Services Provided during Companionship:</h3>
                                <ul>
                                  <li><h4>Emotional Support:</h4> Caregivers offer a listening ear, engage in conversations, and provide a sense of companionship to combat feelings of loneliness and isolation.</li>
                                  <li><h4>Socialization:</h4> Encouraging participation in social activities, outings, games, or hobbies to keep clients engaged and connected with others.</li>
                                  <li><h4>Assistance with Daily Tasks:</h4> Helping with light housekeeping, meal preparation, running errands, scheduling appointments, and other household chores to ensure a comfortable living environment.</li>
                                  <li><h4>Engagement in Activities:</h4> Collaborating on hobbies, playing games, sharing stories or memories, discussing current events to stimulate mental and emotional well-being.</li>
                                  <li><h4>Safety and Security:</h4> Providing companionship that includes monitoring the client’s well-being and ensuring their safety within the home environment.</li>
                                  
                                </ul>
                                <h3>Benefits of Companionship Services:</h3>
                                <ul>
                                  <li><h4>Enhanced Quality of Life:</h4> Companionship services contribute to improved mental health, reduced feelings of isolation, and increased overall happiness for clients.</li>
                                  <li><h4>Promotion of Independence:</h4> By assisting with daily tasks and encouraging social engagement, companionship services help clients maintain their independence for longer periods.</li>
                                  <li><h4>Peace of Mind for Families:</h4> Knowing that their loved ones have regular companionship and support can provide families with reassurance about their well-being.</li>
                                </ul>
                                
                              </div>
                                {start_now}
                            </div>,
                            <div className="service_description_container">
                              <div className="service_title">
                                Personnel Care Overview:
                              </div>
                              <div className="service_description">
                                Personal home care, also known as non-skilled care, non-medical care, or companion care, is a type of service provided by trained aides, certified nursing assistants (CNAs), or therapists to individuals in the comfort of their own homes. This form of care allows people to remain at home for as long as possible, offering an enhanced quality of life compared to other settings. Personal home care focuses on providing individualized care tailored to the specific needs of each person, distinguishing it from more crowded skilled care facilities or rehabilitation centers.

                                <h3>Description of Non-medical Assistance Personal Home Care Service:</h3>
                                <ul>
                                  <li>Assistance with activities of daily living (ADLs) like bathing, toileting, dressing, and grooming.</li>
                                  <li>Basic housekeeping tasks such as cleaning, yard work, and laundry.</li>
                                  <li>Meal preparation and delivery.</li>
                                  <li>Companionship activities like reading aloud, playing games, or chatting.</li>
                                  <li>Assistance with ambulation, transfers, and fall prevention.</li>
                                  <li>Medication reminders.</li>
                                  <li>Running errands and accompanying to appointments.</li>
                                </ul>
                              </div>
                                {start_now}
                            </div>,
                            <div className="service_description_container">
                            <div className="service_title">
                                Home Support Overview:
                            </div>
                              <div className="service_description">
                              Home support services are designed to provide assistance and care to individuals who prefer to receive care in the comfort of their own homes rather than in a nursing home or assisted living facility. Home support services aim to help clients maintain their independence and quality of life while receiving the necessary care and support they need.
                              <h3>Services Provided during Home Support :</h3>
                              <p>Personal Care, Household Tasks, Medication Management, Companionship, Transportation, Caregivers</p>

                              <h3>Benefits of Home Support Services:</h3>

                              <h4>Independence:</h4> Home support services allow individuals to remain in familiar surroundings and maintain their independence.

                              <h4>Personalized Care:</h4> Services are tailored to meet the specific needs and preferences of each client.

                              <h4>Cost-Effective:</h4> In many cases, receiving care at home can be more cost-effective than residing in a nursing home or assisted living facility.
                              </div>
                                {start_now}
                            </div>,
                            <div className="service_description_container">
                            <div className="service_title">
                                Memory Care Overview:
                            </div>
                              <div className="service_description">
                                Memory care (Alzheimer’s / Dementia) for a homecare business involves providing specialized care services to individuals living with Alzheimer’s disease, dementia, or other memory-related conditions within the comfort of their own homes. This type of care is tailored to meet the unique needs of each individual and aims to enhance their quality of life while promoting independence and well-being.

                                <h3>Services Provided during Memory Care:</h3>
                                <ul>
                                  <li><h4>Personalized Care Plans:</h4> Tailored care plans are developed based on the individual’s specific cognitive abilities, preferences, and needs.</li>
                                  <li><h4>Assistance with Activities of Daily Living (ADLs):</h4> Help with tasks such as bathing, dressing, grooming, meal preparation, medication reminders, and mobility assistance.</li>
                                  <li><h4>Cognitive Stimulation Activities:</h4> Engaging activities designed to stimulate memory, cognition, and social interaction.</li>
                                  <li><h4>Safety Measures:</h4> Implementing safety protocols to prevent wandering and ensure a secure environment.</li>
                                  <li><h4>Emotional Support:</h4> Providing companionship, emotional support, and reassurance to individuals experiencing memory loss.</li>
                                  <li><h4></h4> Collaborating with family members to ensure continuity of care and support for both the individual and their loved ones.</li>
                                </ul>
                                
                                <h3>Qualifications for Memory Care Homecare Providers:</h3>
                                <ul>
                                  <li><h4>Training in Dementia Care:</h4> Specialized training in dementia care techniques and understanding of various types of dementia.</li>
                                  <li><h4>Compassion and Patience:</h4> Ability to provide compassionate care with patience and empathy towards individuals with memory impairments.</li>
                                  <li><h4>Experience Working with Dementia Patients:</h4> Prior experience in caring for individuals with dementia to recognize symptoms and behaviors effectively.</li>
                                  <li><h4>Knowledge of Memory Care Therapies:</h4> Familiarity with dementia care therapies and activities that promote cognitive function and overall well-being.</li>
                                </ul>
                                
                                <h3>Benefits of Memory Care Homecare Services:</h3>
                                <ul>
                                  <li><h4>Familiar Environment:</h4> Allows individuals to remain in a familiar setting surrounded by their personal belongings and memories.</li>
                                  <li><h4>Individualized Care:</h4> Customized care plans that address the specific needs and preferences of each client.</li>
                                  <li><h4>Family Support:</h4> Offers support not only to the individual but also to their family members who may be primary caregivers.</li>
                                </ul>
                                
                              </div>
                                {start_now}
                            </div>,
                            <div className="service_description_container">
                            <div className="service_title">
                                After Rehabilitation Nursing Recovery Overview:
                            </div>
                              <div className="service_description"> 
                              <h3>Services Provided during Nursing Recovery</h3>
                                  <ul>
                                    <li></li>

                                  </ul>
                              </div>
                                {start_now}
                            </div>,
                          ]

    const header =  <div className="header">
                      <h1 className="logo">Huduma Homecare LLC</h1>
                      <div className="navigation_buttons">
                        <button className="button-5" onClick={(e)=>{setChoice()}}>Home</button>
                        <button className="button-5" onClick={(e)=>{setChoice(0)}}>Services</button>
                        <button className="button-5" onClick={(e)=>{setChoice(1)}}>About</button>
                        <button className="button-5" onClick={(e)=>{setChoice(2)}}>Careers</button>
                        <button className="button-5" onClick={(e)=>{setChoice(3)}}>Caregivers</button>
                        <button className="button-5" onClick={(e)=>{setChoice(4)}}>Contact</button>
                      </div>
                    </div>

const header2 =  <div className="header">
                  <div className="image_logo">
                    <img src={Image4} alt="" />
                  </div>
                  <h1 className="logo_mobile">Huduma Homecare</h1>
                  <div className="navigation_buttons">
                    <button className="button-5" onClick={(e)=>{setChoice()}}>Home</button>
                    <button className="button-5" onClick={(e)=>{setChoice(0)}}>Services</button>
                    <button className="button-5" onClick={(e)=>{setChoice(1)}}>About</button>
                    <button className="button-5" onClick={(e)=>{setChoice(2)}}>Careers</button>
                    <button className="button-5" onClick={(e)=>{setChoice(3)}}>Caregivers</button>
                    <button className="button-5" onClick={(e)=>{setChoice(4)}}>Contact</button>
                  </div>
                </div>

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  
  if (isMobile) {
    
    switch(choice){
      case 0:
        return (
          <div>
            {header2}
            <div className="container">
              <div className="page_name">
                Our Services
              </div>
            <div className="services">
              <button className="button-37" onClick={()=>{setService(0)}}>Companionship</button>
              <button className="button-37" onClick={()=>{setService(1)}}>Personnel Care</button>
              <button className="button-37" onClick={()=>{setService(2)}}>Home Support</button>
              <button className="button-37" onClick={()=>{setService(3)}}>Memory Care</button>
              <button className="button-37" onClick={()=>{setService(4)}}>After Rehabilitation</button>
            </div>
              {serviceChoice[service]}
            </div>  
          </div>
        )
        break
      case 1:
        return (
          <div>
            {header2}
            <div className="container">
              <div className="page_name">
                About Huduma Homecare LLC
              </div>
              <div className="about_description">
                <div className="about_description_container_mobile">
                  <ul>
                    <h3>Who We Are:</h3>
                    <li>Huduma home-care Means Services for all, We serve our seniors with love, care and kindness. Huduma will provide care from facilities and in home services.</li>
                    <br />
                    <h3>History About Me</h3>
                    <li>I was raised in Kenya where I used to take care of my grandparents with my family. In our community, our seniors are fundamental to our society, and we treat them with dignity, respect, and compassion. Our duty is to make sure they are comfortable and safe.</li>
                    <li>I immigrated to the USA after receiving a full athletic scholarship to Campbell University. I used to work part-time in an assisted living facility. After my graduation in August 2018, I continued working in senior living and pursued further studies to become a Certified Nursing Assistant (CNA). I decided to turn my passion for caring for seniors into my full-time job and started my own home care business.</li>
                    <br />
                    <h3>Mission and Values</h3>
                    <li>I know how much seniors need people with kindness and compassion, and I will make sure my caregivers are individuals who have experience and a genuine interest in taking care of seniors. Every senior in Huduma will receive mobility exercises based on the level they can handle. I have a lot to offer our seniors as I have worked in private homes and facilities myself. I enjoy working with families to provide a safe place for our seniors to enjoy their retirement. Huduma will serve our seniors with care, compassion, kindness, and empathy.</li>
                    
                  </ul>
                  <br />
                  <center><h2>Company Reviews</h2></center>
                  <br />
                  {
                    reviewsCompany.map((Item, id)=>{
                      return (
                        <div key={id} className="companyReview">
                          <div className="review">{Item.Item.Review}</div>
                          <div className="reviewName">- {Item.Item.First_Name}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
                <div className="leave_review">Leave A Review</div>
              <div className="review_form_mobile">
                <div className="uin">
                  <label htmlFor="">First Name</label>
                  <input type="text" name="" id="" onChange={(e)=>{setData1(e.target.value)}} value={data1}/>
                </div>
                <div className="uin">
                  <label htmlFor="">Last Name</label>
                  <input type="text" name="" id="" onChange={(e)=>{setData2(e.target.value)}} value={data2}/>
                </div>
                <div className="uin">
                  <label htmlFor="">E-mail</label>
                  <input type="text" name="" id="" onChange={(e)=>{setData33(e.target.value)}} value={data33}/>
                </div>
                <div className="uin">
                  <label htmlFor="">Review</label>
                  <textarea name="" id="" column="10" rows="5" onChange={(e)=>{setData3(e.target.value)}} value={data3}> 
                  </textarea>
                </div>
                <div className="uin">
                  <label htmlFor="">Who is the review for?</label>
                  <select name="" id="" onChange={(e)=>{setData4(e.target.value)}} value={data4}>
                    
                    {
                      caregiver.map((Item, id)=>{
                        return(
                          <option key={id} value={Item._id}>{Item.Item.First_Name} {Item.Item.Last_Name}</option>
                        )
                      })
                    }

                    <option value="Company">Company</option>
                  </select>
                </div>
                <div className="error">
                  
                    {
                      error?
                      <div>
                        {error}
                      </div>
                      : <div></div>
                    }

                </div>
                <button className="button-2" onClick={(e)=>{DataMark(e, 0)}}>Submit Review</button>
              </div>
              
            </div>
          </div>
        )
        break
      case 2:
        return (
          <div>
            {header2}
            <div className="container">  
              <div className="page_name">
                Join Our Team
              </div>
              <div className="career_description">
                <div className="career_header">
                  <div>
                    <img src={Image4} alt="" />
                    <h1>Non-Certified Aides</h1>
                  </div>
                  <p>Aides </p>
                  <p>Home Helpers</p>
                  <p>Homemakers</p>
                  <p>Chore Workers</p>
                  <p>Companions</p> 

                  <div className="second_career">
                    <img src={Image3} alt="" />
                    <h1>Certified Aides</h1>
                      <p>Certified Nursing Assistants (CNA)</p>
                      <p>Home Health Aides</p>
                  
                  </div>

                  <div>
                    <h1>Nurses</h1>
                    <img src={Image2} alt="" />
                  </div>
                  <p>Licensed Practical Nurse (LPN)</p>
                  <p>Licensed Vocational Nurses (LVN)</p>

                </div>
              </div>

              <div className="nurse">
                <center><h1>Apply Here</h1></center>
                <div className="nurse_career_form_mobile">
                    <div className="uin">
                      <label htmlFor="">First Name</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData5(e.target.value)}} value={data5}/>
                    </div>
                    <div className="uin">
                      <label htmlFor="">Last Name</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData6(e.target.value)}} value={data6}/>
                    </div>
                    <div className="uin">
                      <label htmlFor="">E-mail</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData7(e.target.value)}} value={data7}/>
                    </div>
                    <div className="uin">
                      <label htmlFor="">Phone</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData8(e.target.value)}} value={data8}/>
                    </div>
                    <div>
                      <label htmlFor="">Resume</label>
                      <input type="file" name="" id="" onChange={(e)=>{INeedThisToDoSometing(e.target)}} />
                    </div>
                    <div>
                      <label htmlFor="">Photo of yourself</label>
                      <input type="file" name="" id="" onChange={(e)=>{INeedThisToDoSometing2(e.target)}} />
                    </div>
                    <div>
                      <label htmlFor="">Applying Position</label>
                      <select name="" id="" onChange={(e)=>{setData11(e.target.value)}} value={data11}>
                        <option value=""></option>
                        <option value="Nurse">Nurse</option>
                        <option value="CNA">Certified Aide</option>
                        <option value="NCNA">Non-Certified Aide</option>
                      </select>
                    </div>
                    <div className="error">
                      
                    {
                      error?
                      <div>
                        {error}
                      </div>
                      : <div></div>
                    }
                    </div>
                    <button className="button-2" onClick={(e)=>{DataMark(e, 2)}}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 3: return(
                <div>
                  {header2}
                  <div className="container">
                    <div className="page_name">
                        Our Team
                    </div>
                    <div className="nurse_container">
                        <div className="aideReview">

                                    {
                                      aideImage?
                                      <div><div className="nurseImg">
                                        <img src={aideImage} alt="" />
                                      </div>
                                      <div className="aideReviews">
                                          {
                                          aideReview.map((Item, id)=>{
                                            return(
                                              <div key={id} className="aideReviewContainer">
                                                <div className="reviewAide">{Item.Item.Review}</div>
                                                <div className="reviewName">- {Item.Item.First_Name}</div>
                                              </div>
                                            )
                                          })
                                          }
                                      </div></div>
                                      : <div className="Our_Nurses">
                                          <h1>Qualities in our Huduma Homecare Team:</h1>
                                          <div>
                                            <div className="team_description"><h3>Nurses:</h3> The nurses at Huduma Homecare LLC are highly skilled and experienced professionals who provide exceptional care to clients in the comfort of their homes. They are licensed, compassionate, and dedicated to ensuring the well-being and safety of their patients.</div>
                                            <div className="team_description"><h3>Certified Nursing Aides:</h3> The Certified Nursing Aides at Huduma Homecare LLC are trained healthcare professionals who work under the supervision of nurses to assist with daily activities, personal care, and monitoring the health status of clients. They play a crucial role in supporting the nursing staff and providing quality care to individuals in need.</div>
                                            <div className="team_description"><h3>Non-Certified Aides:</h3> The Non-Certified Aides at Huduma Homecare LLC are carefully selected individuals who undergo thorough training and background checks before joining the team. While they may not have formal certifications, they receive proper guidance and supervision to deliver compassionate care and support to clients under the direction of licensed professionals.</div>
                                          </div>
                                      </div>
                                    }
                        </div>
                      {
                        caregiver.map((Item, id)=>{
                          return(
                            <div key={id} className="aideProfile">
                              <div className="nurse_info">
                                <div className="nurseName">
                                    <h3 className="nurseName">{Item.Item.First_Name} {Item.Item.Last_Name}</h3>
                                </div>
                                <div className="nurse_description">
                                    <h3>{Item.Item.Description}</h3>
                                </div>
                                <div className="">
                                    <button className="button-2" onClick={(e)=>{DataMark(e, 5, Item._id); DataMark(e, 4, Item._id)}}>View Nurse</button>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    
                    </div>
                  </div>
                </div>
              )
      case 4: return (
                <div>
                  {header2}
                  <div className="container">
                    <div className="page_name">Contact Us Today</div>
                    <div className="image_container">
                      <img src={Image1} alt="" />
                    </div>

                    <div className="contact_form_mobile">
                      <div className="uin">
                        <label htmlFor="">First Name</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData12(e.target.value)}} value={data12}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Last Name</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData13(e.target.value)}} value={data13}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">E-mail</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData14(e.target.value)}} value={data14}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Phone Number</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData15(e.target.value)}} value={data15}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Location</label>
                        <select name="" id="" onChange={(e)=>{setData16(e.target.value)}} value={data16}>
                          <option value=""></option>
                          <option value="Apex">Apex</option>
                          <option value="Cary">Cary</option>
                          <option value="Chapel Hill">Chapel Hill</option>
                          <option value="Durham">Durham</option>
                          <option value="Fuquay">Fuquay</option>
                          <option value="Raleigh">Raleigh</option>
                        </select>
                      </div>

                      <div className="uin">
                        <label htmlFor="">Service</label>
                        <select name="" id="" onChange={(e)=>{setData17(e.target.value)}} value={data17}>
                          <option value=""></option>
                          <option value="Companionship">Companionship</option>
                          <option value="Personnal Care">Personnal Care</option>
                          <option value="Home Support">Home Support</option>
                          <option value="Memory Caere">Memory Care</option>
                          <option value="Nursing Recovery">Nursing Recovery</option>
                        </select>
                      </div>

                      <div className="error">
                        
                      {
                        error?
                        <div>
                          {error}
                        </div>
                        : <div></div>
                      }
                      </div>
                      <button className="button-2" onClick={(e)=>{DataMark(e, 3)}}>Submit</button>
                    </div>  
                  </div>
                </div>
              )
        break

        case "5":
          return(
            <div>
                <center>
                  <p>This web app is currently under development.</p>
                  <button className="button-5" onClick={(e)=>{setChoice("0")}}>Accept and Continue</button>
                </center>
            </div>
          )

      default: 
        return (
          <div>
            {header2}
            <div className="">
              <div className="about">
                <h1 className="home_words_mobile">A New</h1>
                <h1 className="home_words_mobile">Standard in</h1>
                <h1 className="home_words_mobile">Homecare</h1>
                <h1 className="home_words_mobile">Services</h1>
              </div>  
                <img className="owner_image" src={Image5} alt="" />
                <center><b className="button-2"onClick={(e)=>{setChoice(1)}}>About</b><button className="button-2" onClick={(e)=>{setChoice(0)}}>Services</button></center>
            </div>
          </div>
        )
        break;
          
    }
  }else{

    switch(choice){
      case 0:
        return (
          <div>
            {header}
            <div className="container">
              <div className="page_name">
                Our Services
              </div>
            <div className="services">
              <button className="button-37" onClick={()=>{setService(0)}}>Companionship</button>
              <button className="button-37" onClick={()=>{setService(1)}}>Personnel Care</button>
              <button className="button-37" onClick={()=>{setService(2)}}>Home Support</button>
              <button className="button-37" onClick={()=>{setService(3)}}>Memory Care</button>
              <button className="button-37" onClick={()=>{setService(4)}}>After Rehabilitation</button>
            </div>
              {
                serviceChoice[service]
              }
            </div>  
          </div>
        )
        break
      case 1:
        return (
          <div>
            {header}
            <div className="container">
              <div className="page_name">
                About Huduma Homecare LLC
              </div>
              <div className="about_description">
                <div className="about_description_container">
                  <ul>
                    <h3>Who We Are:</h3>
                    <li>Huduma home-care Means Services for all, We serve our seniors with love, care and kindness. Huduma will provide care from facilities and in home services.</li>
                   
                    <br />
                    <h3>History About Me</h3>
                    <li>I was raised in Kenya where I used to take care of my grandparents with my family. In our community, our seniors are fundamental to our society, and we treat them with dignity, respect, and compassion. Our duty is to make sure they are comfortable and safe.</li>
                    <li>I immigrated to the USA after receiving a full athletic scholarship to Campbell University. I used to work part-time in an assisted living facility. After my graduation in August 2018, I continued working in senior living and pursued further studies to become a Certified Nursing Assistant (CNA). I decided to turn my passion for caring for seniors into my full-time job and started my own home care business.</li>
                    <br />
                    <h3>Mission and Values</h3>
                    <li>I know how much seniors need people with kindness and compassion, and I will make sure my caregivers are individuals who have experience and a genuine interest in taking care of seniors. Every senior in Huduma will receive mobility exercises based on the level they can handle. I have a lot to offer our seniors as I have worked in private homes and facilities myself. I enjoy working with families to provide a safe place for our seniors to enjoy their retirement. Huduma will serve our seniors with care, compassion, kindness, and empathy.</li>
                   
                  </ul>
                  <br />
                  <br />
                  <center><h2>Company Reviews</h2></center>
                  {
                    reviewsCompany.map((Item, id)=>{
                      return (
                        <div key={id} className="companyReview">
                          <div className="review">{Item.Item.Review}</div>
                          <div className="reviewName">- {Item.Item.First_Name}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
                <div className="leave_review">Leave A Review</div>
              <div className="review_form">
                <div className="uin">
                  <label htmlFor="">First Name</label>
                  <input type="text" name="" id="" onChange={(e)=>{setData1(e.target.value)}} value={data1}/>
                </div>
                <div className="uin">
                  <label htmlFor="">Last Name</label>
                  <input type="text" name="" id="" onChange={(e)=>{setData2(e.target.value)}} value={data2}/>
                </div>
                <div className="uin">
                  <label htmlFor="">E-mail</label>
                  <input type="text" name="" id="" onChange={(e)=>{setData33(e.target.value)}} value={data33}/>
                </div>
                <div className="uin">
                  <label htmlFor="">Review</label>
                  <textarea name="" id="" column="10" rows="5" onChange={(e)=>{setData3(e.target.value)}} value={data3}> 
                  </textarea>
                </div>
                <div className="uin">
                  <label htmlFor="">Who is the review for?</label>
                  <select name="" id="" onChange={(e)=>{setData4(e.target.value)}} value={data4}>
                    
                    {
                      caregiver.map((Item, id)=>{
                        return(
                          <option key={id} value={Item._id}>{Item.Item.First_Name} {Item.Item.Last_Name}</option>
                        )
                      })
                    }

                    <option value=""></option>
                    <option value="Company">Company</option>
                  </select>
                </div>
                {
                  error?
                  <div>
                    {error}
                  </div>
                  : <div></div>
                }
                <button className="button-2" onClick={(e)=>{DataMark(e, 0)}}>Submit Review</button>
              </div>
              
            </div>
          </div>
        )
        break
      case 2:
        return (
          <div>
            {header}
            <div className="container">  
              <div className="page_name">
                Join Our Team
              </div>
              <div className="">
                <div className="career_header">
                  <div>
                    <img src={Image4} alt="" />
                    <h1>Non-Certified Aides</h1>
                  </div>
                  <p>Aides </p>
                  <p>Home Helpers</p>
                  <p>Homemakers</p>
                  <p>Chore Workers</p>
                  <p>Companions</p> 

                  <div className="second_career">
                    <img src={Image3} alt="" />
                    <h1>Certified Aides</h1>
                      <p>Certified Nursing Assistants (CNA)</p>
                      <p>Home Health Aides</p>
                  
                  </div>

                  <div>
                    <h1>Nurses</h1>
                    <img src={Image2} alt="" />
                  </div>
                  <p>Licensed Practical Nurse (LPN)</p>
                  <p>Licensed Vocational Nurses (LVN)</p>

                </div>
              </div>

              <div className="nurse">
                <center><h1>Apply Here</h1></center>
                <div className="nurse_career_form">
                    <div className="uin">
                      <label htmlFor="">First Name</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData5(e.target.value)}} value={data5}/>
                    </div>
                    <div className="uin">
                      <label htmlFor="">Last Name</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData6(e.target.value)}} value={data6}/>
                    </div>
                    <div className="uin">
                      <label htmlFor="">E-mail</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData7(e.target.value)}} value={data7}/>
                    </div>
                    <div className="uin">
                      <label htmlFor="">Phone</label>
                      <input type="text" name="" id="" onChange={(e)=>{setData8(e.target.value)}} value={data8}/>
                    </div>
                    <div>
                      <label htmlFor="">Resume</label>
                      <input type="file" name="" id="" onChange={(e)=>{INeedThisToDoSometing(e.target)}} />
                    </div>
                    <div>
                      <label htmlFor="">Photo of yourself</label>
                      <input type="file" name="" id="" onChange={(e)=>{INeedThisToDoSometing2(e.target)}} />
                    </div>
                    <div>
                      <label htmlFor="">Applying Position</label>
                      <select name="" id="" onChange={(e)=>{setData11(e.target.value)}} value={data11}>
                        <option value=""></option>
                        <option value="Nurse">Nurse</option>
                        <option value="CNA">Certified Aide</option>
                        <option value="NCNA">Non-Certified Aide</option>
                      </select>
                    </div>
                    <div className="error">
                      
                      {
                        error?
                        <div>
                          {error}
                        </div>
                        : <div></div>
                      }
                    </div>
                    <button className="button-2" onClick={(e)=>{DataMark(e, 2)}}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 3: return(
                <div>
                  {header}
                  <div className="container">
                    <div className="page_name">
                        Our Team
                    </div>
                    <div className="nurse_container">
                        <div className="aideReview">

                                    {
                                      aideImage?
                                      <div><div className="nurseImg">
                                        <img src={aideImage} alt="" />
                                      </div>
                                      <div className="aideReviews">
                                          {
                                          aideReview.map((Item, id)=>{
                                            return(
                                              <div key={id} className="aideReviewContainer">
                                                <div className="reviewAide">{Item.Item.Review}</div>
                                                <div className="reviewName">- {Item.Item.First_Name}</div>
                                              </div>
                                            )
                                          })
                                          }
                                      </div></div>
                                      : <div className="Our_Nurses">
                                          <h1>Qualities in our Huduma Homecare Team:</h1>
                                          <div>
                                            <div className=""><h3>Nurses:</h3> The nurses at Huduma Homecare LLC are highly skilled and experienced professionals who provide exceptional care to clients in the comfort of their homes. They are licensed, compassionate, and dedicated to ensuring the well-being and safety of their patients.</div>
                                            <div className=""><h3>Certified Nursing Aides:</h3> The Certified Nursing Aides at Huduma Homecare LLC are trained healthcare professionals who work under the supervision of nurses to assist with daily activities, personal care, and monitoring the health status of clients. They play a crucial role in supporting the nursing staff and providing quality care to individuals in need.</div>
                                            <div className=""><h3>Non-Certified Aides:</h3> The Non-Certified Aides at Huduma Homecare LLC are carefully selected individuals who undergo thorough training and background checks before joining the team. While they may not have formal certifications, they receive proper guidance and supervision to deliver compassionate care and support to clients under the direction of licensed professionals.</div>
                                          </div>
                                      </div>
                                    }
                        </div>
                      {
                        caregiver.map((Item, id)=>{
                          return(
                            <div key={id} className="aideProfile">
                              <div className="nurse_info">
                                <div className="nurseName">
                                    <h3 className="nurseName">{Item.Item.First_Name} {Item.Item.Last_Name}</h3>
                                </div>
                                <div className="nurse_description">
                                    <h3>{Item.Item.Description}</h3>
                                </div>
                                <div className="">
                                    <button className="button-2" onClick={(e)=>{DataMark(e, 5, Item._id); DataMark(e, 4, Item._id)}}>View Nurse</button>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    
                    </div>
                  </div>
                </div>
              )
      case 4: return (
                <div>
                  {header}
                  <div className="container">
                    <div className="page_name">Contact Us Today</div>
                    <div className="image_container">
                      <img src={Image1} alt="" />
                    </div>

                    <div className="contact_form">
                      <div className="uin">
                        <label htmlFor="">First Name</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData12(e.target.value)}} value={data12}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Last Name</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData13(e.target.value)}} value={data13}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">E-mail</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData14(e.target.value)}} value={data14}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Phone Number</label>
                        <input type="text" name="" id="" onChange={(e)=>{setData15(e.target.value)}} value={data15}/>
                      </div>
                      <div className="uin">
                        <label htmlFor="">Location</label>
                        <select name="" id="" onChange={(e)=>{setData16(e.target.value)}} value={data16}>
                          <option value=""></option>
                          <option value="Apex">Apex</option>
                          <option value="Cary">Cary</option>
                          <option value="Chapel Hill">Chapel Hill</option>
                          <option value="Durham">Durham</option>
                          <option value="Fuquay">Fuquay</option>
                          <option value="Raleigh">Raleigh</option>
                        </select>
                      </div>

                      <div className="uin">
                        <label htmlFor="">Service</label>
                        <select name="" id="" onChange={(e)=>{setData17(e.target.value)}} value={data17}>
                          <option value=""></option>
                          <option value="Companionship">Companionship</option>
                          <option value="Personnal Care">Personnal Care</option>
                          <option value="Home Support">Home Support</option>
                          <option value="Memory Caere">Memory Care</option>
                          <option value="Nursing Recovery">Nursing Recovery</option>
                        </select>
                      </div>

                      <div className="error">
                        
                        {
                          error?
                          <div>
                            {error}
                          </div>
                          : <div></div>
                        }
                      </div>
                      <button className="button-2" onClick={(e)=>{DataMark(e, 3)}}>Submit</button>
                    </div>  
                  </div>
                </div>
              )
        break
      case "5":
            return(
              <div>
                  <center>
                    <p>This web app is currently under development.</p>
                    <button className="button-5" onClick={(e)=>{setChoice("0")}}>Accept and Continue</button>
                  </center>
              </div>
            )
      default: 
        return (
          <div>
            {header}
            <div className="container">
              <div className="about">
                <h1 className="home_words">A New Standard in </h1>
                <h1 className="home_words">Homecare Services</h1>
                <center><button className="button-2" onClick={(e)=>{setChoice(0)}}>Services</button></center>
              </div>
            </div>
          </div>
        )
        break;
          
    }
  }
}

export default App;
